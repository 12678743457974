import { AppModalProps } from '../components/AppModal'
import { AppSnackbarProps } from '../components/AppSnackbar'

import { createCtx, KycStatus } from '../utils/helper'

const AppModalOption: AppModalProps = {
  visible: false,
  message: '',
  children: undefined,
}

const AppSnackbarOption: AppSnackbarProps = {
  visible: false,
  message: '',
  description: '',
  type: 'info',
}

export const AppCtx = createCtx({
  appModal: AppModalOption,
  appSnackbar: AppSnackbarOption,
  kycStatus: undefined as KycStatus | undefined,
  userDisplay: undefined as string | undefined,
  avatarId: undefined as string | undefined,
  totalUnreadNoti: undefined as number | undefined,
  newPinCode: '',
  authenPinCode: '',
  topbarTab: {
    visibled: false,
    tabs: [{ label: '', path: '' }],
    onOk: null,
    onCancel: null,
    title: '',
    message: '',
    children: null,
    closeOnClickOutside: true,
    closeIcon: false,
    style: {},
  },
  pinVerifyCallback: (pinCode: string) => console.log('default fuction'),
  confirmPinCallback: async (pin: string, newPin: string) => console.log('confirm pin'),
})
