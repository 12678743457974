import React, { useCallback, useEffect, useLayoutEffect } from 'react'
import { useLocation } from 'react-router-dom'
import './App.css'
import { AppSnackbar } from './components/AppSnackbar'

import { isMode, withCtx } from './utils/helper'
import { AppCtx } from './constant/contexts'
import { QueryClient, QueryClientProvider } from 'react-query'
import { useSnackbar } from './utils/custom-hook'
import { useTranslation } from 'react-i18next'
import { AnyObject } from 'final-form'
import { PRIMARY, SECONDARY, WHITE } from './constant/colors'
import { FadeLoader, RingLoader } from 'react-spinners'
import { ReactQueryDevtools } from 'react-query/devtools'
import AppModal from './components/AppModal'
// import tracking from './utils/tracking'
import './initialize'

const AppRoute = React.lazy(() => import('./routes'))

const queryClient = new QueryClient()

export const useOnError = () => {
  const { snackbar } = useSnackbar()
  const { t } = useTranslation('backend')
  return useCallback(
    (e: any) => {
      console.log('useOnError', e)
      const ignoreStatus = [8001]
      const {
        message = 'Not Identify',
        meta = {},
        status,
      } = e || ({} as { message: any; status: number; meta: { param?: AnyObject } })
      const { param = {} } = meta
      if (!ignoreStatus.includes(status)) {
        snackbar({
          type: 'error',
          message: t(message, { ...param, defaultValue: message }),
        })
      }
    },
    [snackbar, t],
  )
}

export const FallBackComponent = () => {
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: '100vh',
        backgroundColor: WHITE,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <FadeLoader color={SECONDARY} loading={true} />
      {/* <CircularProgress /> */}
    </div>
  )
}

export const App: React.FC<{ id: string }> = () => {
  const { pathname } = useLocation()
  const onError = useOnError()

  // const mockData = useCallback(() => {
  //   const regId = 'cb22706d-b441-446f-b043-eb9e347950ea'
  //   const appToken = 'EUUYGQr2GjfAApDjz2QbyPaA9wkHQ6fJLVwsP2aSiMGj'
  //   Helper.setEncryptValue(StoreKey.regId, regId)
  //   Helper.setEncryptValue(StoreKey.appToken, appToken)
  // }, [])

  // useEffect(() => {
  //   mockData()
  // }, [mockData])

  useEffect(() => {
    document.body.scrollTo(0, 0)
  }, [pathname])

  // useEffect(() => {
  //   tracking.page(pathname)
  // }, [pathname])

  useLayoutEffect(() => {
    // set react query default options here
    queryClient.setDefaultOptions({
      queries: {
        onError,
        retry: 1,
        retryDelay: 1000,
        cacheTime: 5 * 60 * 1000, // 5 minute
        staleTime: 5 * 60 * 1000, // 5 minute
        refetchOnWindowFocus: false,
      },
      mutations: {
        onError,
        retry: 0,
      },
    })
  }, [onError])

  return (
    <QueryClientProvider client={queryClient}>
      <AppModal />
      <AppSnackbar />
      <React.Suspense fallback={<FallBackComponent />}>
        <AppRoute />
      </React.Suspense>

      {isMode('development') && <ReactQueryDevtools initialIsOpen={false} />}
    </QueryClientProvider>
  )
}

export default withCtx(AppCtx)(App)
