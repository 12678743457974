import axios, { AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse } from 'axios'
import dayjs from 'dayjs'
import { camelizeKeys, decamelizeKeys } from 'humps'
import { merge } from 'lodash'
import config from '../../config'
import { callRefreshToken } from '../../services/ms-ropc/ms-ropc-query'
import { ApiHelper } from '../api-helper'
import { ContentType, customRequestData, deepLoop } from './tools'

type RefreshType = {
  resolve: () => any
  reject: (error: any) => any
}

let isRefreshing = false
let refreshQueue: RefreshType[] = []
const retries = 1

const createClient = () => {
  const ax = axios.create({
    baseURL: config.apiHost,
  })
  ax.interceptors.request.use(async (request: AxiosRequestConfig<any>) => {
    request.url = `${request.url}`

    const headers: AxiosRequestHeaders = {}
    const idToken = ApiHelper.getIdToken()
    if (idToken) {
      headers['Authorization'] = `Bearer ${idToken}`
    }
    headers['33-Smart-Apim-Key'] = await ApiHelper.generateApimToken()
    headers['App-Version'] = ApiHelper.appVersion
    headers['App-Platform'] = ApiHelper.appPlatform
    request.headers = { ...request.headers, ...headers }

    if (request.headers['Content-Type'] !== ContentType.APPLICATION_XML) {
      if (request.params) {
        request.params = decamelizeKeys(request.params)
        request.params = deepLoop(request.params, modifyRequestData)
      }
      if (request.data) {
        request.data = decamelizeKeys(request.data)
        request.data = deepLoop(request.data, modifyRequestData)
        customRequestData(request)
      }
    }
    return request
  })
  ax.interceptors.response.use(
    (response: AxiosResponse<any>) => {
      if (response.headers['content-type'].includes('application/json')) {
        response.data = camelizeKeys(response.data)
      }

      if (response?.data?.status !== undefined && response?.data?.status !== 0) {
        return Promise.reject(response)
      }

      return response
    },
    async (error) => {
      const {
        config: orgConfig,
        response: { status },
      } = error

      if (status !== 401) {
        return Promise.reject(error)
      }

      orgConfig._retry = typeof orgConfig._retry === 'undefined' ? 0 : ++orgConfig._retry

      if (orgConfig._retry === retries) {
        return Promise.reject(error)
      }

      if (!isRefreshing) {
        isRefreshing = true
        callRefreshToken()
          .then((res) => {
            refreshQueue.forEach((v) => v.resolve())
            refreshQueue = []
          })
          .catch(() => {
            refreshQueue.forEach((v) => v.reject(error))
            refreshQueue = []
          })
          .finally(() => {
            isRefreshing = false
          })
      }

      return new Promise((resolve, reject) => {
        refreshQueue.push({
          resolve: () => {
            const config = merge(orgConfig)
            const idToken = ApiHelper.getIdToken()
            if (idToken) {
              config.headers['Authorization'] = `Bearer ${idToken}`
            }
            resolve(axios.request(config))
          },
          reject: (error: any) => {
            reject(error)
          },
        })
      })
    },
  )

  return ax
}

const modifyRequestData = (data: any) => {
  if (dayjs.isDayjs(data)) {
    return data.format()
  }
  return data
}

export const smartSwiftClient = createClient()

export const smartSwiftApiWraper = async (method: Promise<AxiosResponse>) => {
  try {
    const res = await method
    return Promise.resolve(res)
  } catch (e: any) {
    const { response, message, data } = e
    return Promise.reject(data || response || message || e)
  }
}
