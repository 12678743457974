import { Language } from '@mui/icons-material'
import { Button, Menu, MenuItem } from '@mui/material'
import Roboto from '../common/Roboto'
import { useState, useMemo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { SECONDARY } from '../../constant/colors'

export const initLangKey = 'blcp-lang'

const LanguageSwitchMenu = () => {
  const { i18n } = useTranslation()

  const currentLang = useMemo(() => {
    switch (i18n.language) {
      case 'en':
        return 'English'
      case 'kh':
        return 'Cambodia'
      case 'th':
        return 'Thai'
    }
    return i18n.language
  }, [i18n.language])

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleChangeLang = useCallback(
    (event: React.MouseEvent<HTMLElement>, language: string | null) => {
      if (language !== null) {
        i18n.changeLanguage(language)
        i18next.reloadResources()
        localStorage.setItem(initLangKey, language)
        setAnchorEl(null)
      }
    },
    [i18n],
  )

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Button
        id="basic-button"
        aria-controls="switch-lang-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        variant="text"
        style={{ textTransform: 'none', width: 120 }}
        startIcon={
          <Language fontSize="small" color="secondary" style={{ width: '24px', height: '24px' }} />
        }
      >
        <Roboto color={SECONDARY} type="Body">
          {currentLang}
        </Roboto>
      </Button>
      <Menu
        id="switch-lang-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={(event) => handleChangeLang(event, 'en')}>
          <Roboto color="#33BDA1" type="Body">
            English
          </Roboto>
        </MenuItem>
        <MenuItem onClick={(event) => handleChangeLang(event, 'kh')}>
          <Roboto color="#33BDA1" type="Body">
            Cambodia
          </Roboto>
        </MenuItem>
        <MenuItem onClick={(event) => handleChangeLang(event, 'th')}>
          <Roboto color="#33BDA1" type="Body">
            Thai
          </Roboto>
        </MenuItem>
      </Menu>
    </>
  )
}

export default LanguageSwitchMenu
