import { Context, createContext, useCallback, useMemo, useRef, useState } from 'react'
import deepmerge from 'deepmerge'
import { difference, isEqual } from 'lodash'
import { AnyObject } from 'final-form'
import { useHistory, useLocation, useParams, useRouteMatch } from 'react-router'
import CryptoJS from 'crypto-js'
import qs from 'qs'
import config from '../config'
import { AxiosResponse } from 'axios'
import downloadjs from 'downloadjs'
import { TEXT, WARNING, INFO, ERROR, SUCCESS } from '../constant/colors'

export const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

export const createCtx = <T extends object>(initValue: T) => {
  return createContext<
    [T, (value: DeepPartial<T>) => void, { reset: () => void; initialValue: T }]
  >([
    initValue,
    (value: DeepPartial<T>) => {},
    {
      reset: () => {},
      initialValue: initValue,
    },
  ])
}

const overwriteMerge = (destinationArray: any, sourceArray: any, options: any) => sourceArray

export const withCtx =
  <T extends AnyObject = AnyObject>(Context: Context<any>) =>
  (Component: React.ElementType) =>
  (props: T) => {
    const initalState = useMemo(() => {
      return (Context as any)._currentValue[0] || {}
    }, [])
    const [state, setState] = useState(initalState)
    const ref = useRef(state)
    const customSetState = useCallback((v: any) => {
      const newState = deepmerge(ref.current, v, { arrayMerge: overwriteMerge })
      if (!isEqual(newState, ref.current)) {
        ref.current = newState
        setState(newState)
      }
    }, [])
    const reset = useCallback(() => {
      if (!isEqual(initalState, ref.current)) {
        ref.current = initalState
        setState(initalState)
      }
    }, [initalState])

    return (
      <Context.Provider value={[state, customSetState, { reset, initialValue: initalState }]}>
        <Component {...props} />
      </Context.Provider>
    )
  }

export const useRouter = <TQuery extends any = any>() => {
  const params = useParams()
  const location = useLocation()
  const history = useHistory()
  const match = useRouteMatch()
  const query = useMemo(() => {
    return {
      ...qs.parse(location.search.slice(1)),
      ...params,
    } as TQuery
  }, [location.search, params])

  return useMemo(() => {
    return {
      push: history.push,
      replace: history.replace,
      goBack: history.goBack,
      pathname: location.pathname,
      query,
      match,
      location,
    }
  }, [history.push, history.replace, history.goBack, location, query, match])
}

const defaultMode = ['production', 'uat', 'staging', 'development'] as const

export const isMode = (...mode: typeof defaultMode[number][]) => {
  const buildMode = config.mode as typeof defaultMode[number]
  return mode.includes(buildMode)
}

export const formatToCurrency = (amount: number) => {
  return amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
}

export const formatToCurrencySevenDecimal = (amount: number) => {
  return amount.toFixed(7).replace(/\d(?=(\d{3})+\.)/g, '$&,')
}

export const normalizePhone = (value: string) => {
  if (!value) {
    return value
  }
  const onlyNums = value.replace(/[^\d]/g, '')
  if (onlyNums.length <= 3) {
    return onlyNums
  }
  if (onlyNums.length <= 6) {
    return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 6)}`
  }
  return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 10)}`
}

export const accountInputFormatter = (value: string) => {
  if (!value) {
    return value
  }
  const onlyNums = value.replace(/[^\d]/g, '')
  if (onlyNums.length <= 3) {
    return onlyNums
  }
  if (onlyNums.length <= 4) {
    return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 4)}`
  }
  if (onlyNums.length <= 9) {
    return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 4)}-${onlyNums.slice(4, 9)}`
  }
  return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 4)}-${onlyNums.slice(4, 9)}-${onlyNums.slice(
    9,
    10,
  )}`
}

export const normalizeAccount = (value: string) => {
  if (!value) {
    return value
  }
  const onlyNums = value.replace(/[^\d]/g, '')
  if (onlyNums.length <= 3) {
    return onlyNums
  }
  if (onlyNums.length <= 6) {
    return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 6)}`
  }
  return `xxx-x-x${onlyNums.slice(5, 9)}-x`
}

export const downloadFile = async (res: AxiosResponse<Blob>, fileName: string) => {
  downloadjs(res.data, decodeURIComponent(fileName))
}

export enum StoreKey {
  regId,
  regIdForgotPin,
  appToken,
  mobileTelNo,
  isLogin,

  // config
  authUrl,
  authClientId,

  // for MS ROPC
  idToken,
  refreshToken,
  accessToken,
  tokenType,
  expiresIn,

  // Refresh Token
  notBefore,
  expiresOn,
  resource,
  idTokenExpiresIn,
  profileInfo,
  scope,
  refreshTokenExpiresIn,
  // end MS ROPC
}
export class Helper {
  static salt = process.env.REACT_APP_ENCRYPT_SALT || 'encryp-salt'

  static async setIdToken(accessToken: string) {
    this.setEncryptValue(StoreKey.idToken, accessToken)
  }

  static getIdToken(): string {
    return this.getEncryptValue(StoreKey.idToken)
  }

  static setEncryptValue(key: StoreKey, value: any) {
    const encryptValue = this.encryptData(value, this.salt)
    localStorage.setItem(`${key}`, encryptValue)
  }

  static getEncryptValue(key: StoreKey): string {
    const _value = localStorage.getItem(`${key}`)
    if (_value) {
      try {
        const decryptValue = this.decryptData(_value, this.salt)
        return decryptValue
      } catch (error) {
        return ''
      }
    } else {
      return ''
    }
  }

  static encryptData = (data: Record<string, any>, salt: string) =>
    CryptoJS.AES.encrypt(JSON.stringify(data), salt).toString()

  static decryptData = (cipherText: string, salt: string) => {
    const bytes = CryptoJS.AES.decrypt(cipherText, salt)
    try {
      return JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
    } catch (err) {
      return null
    }
  }

  static clearStore = () => {
    const keys = Object.keys(StoreKey).filter((item) => {
      return !isNaN(Number(item))
    })
    const ignore = [StoreKey.authUrl, StoreKey.authClientId].map((v) => `${v}`)
    const removeKeys = difference(keys, ignore)

    removeKeys.forEach((removeKey) => {
      return localStorage.removeItem(removeKey)
    })
  }
}

export const useQueryParams = <T extends object>() => {
  const router = useRouter()
  const setParam = useCallback(
    (value: T) => {
      router.push(`${router.pathname}?${qs.stringify({ ...router.query, ...value })}`)
    },
    [router],
  )
  return {
    query: router.query as T,
    setParam,
  }
}

export const normalizeTime = (value: any) => {
  if (!value) return value
  const onlyNums = value.replace(/[^\d]/g, '')
  if (onlyNums.length <= 3) return onlyNums
  return `${onlyNums.slice(0, 2)}:${onlyNums.slice(2, 4)}`
}

export const normalizeBankDigits = (value: any) => {
  if (!value) return value
  const onlyNums = value.replace(/[^\d]/g, '')
  return onlyNums
}

export const colorStatus = (status: string) => {
  var color = TEXT
  switch (status) {
    case 'UP':
      color = WARNING
      break
    case 'PD':
      color = INFO
      break
    case 'CC':
      color = ERROR
      break
    case 'RJ':
      color = ERROR
      break
    case 'CP':
      color = SUCCESS
      break
    default:
      color = TEXT
      break
  }
  return color
}

export enum KycStatus {
  UV = 'UV',
  PD = 'PD',
  VF = 'VF',
  RJ = 'RJ',
  XX = 'XX',
}

export function enumFromStringValue<T>(enm: { [s: string]: T }, value: string): T | undefined {
  return (Object.values(enm) as unknown as string[]).includes(value)
    ? (value as unknown as T)
    : undefined
}
