import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { createTheme, ThemeProvider } from '@mui/material'
import { I18nextProvider } from 'react-i18next'
import i18n from './constant/i18n'
import { createBrowserHistory } from 'history'
import { Router } from 'react-router'
import {
  ERROR,
  INFO,
  PRIMARY,
  PRIMARY_LIGHT,
  SECONDARY,
  SECONDARY_LIGHT,
  SUCCESS,
  TEXT,
  WARNING,
  WHITE,
} from './constant/colors'

const history = createBrowserHistory()

export const theme = createTheme({
  palette: {
    primary: {
      main: PRIMARY,
      light: PRIMARY_LIGHT,
      dark: PRIMARY,
      contrastText: WHITE,
    },
    secondary: {
      main: SECONDARY,
      light: SECONDARY_LIGHT,
      dark: SECONDARY,
      contrastText: WHITE,
    },
    error: {
      main: ERROR,
      light: ERROR,
      dark: ERROR,
      contrastText: WHITE,
    },
    warning: {
      main: WARNING,
      light: WARNING,
      dark: WARNING,
      contrastText: TEXT,
    },
    info: {
      main: INFO,
      light: INFO,
      dark: INFO,
      contrastText: WHITE,
    },
    success: {
      main: SUCCESS,
      light: SUCCESS,
      dark: SUCCESS,
      contrastText: WHITE,
    },
  },
  typography: {
    fontFamily: `"Kanit"`,
    fontSize: 17,
  },
})

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <React.StrictMode>
      <Router history={history}>
        <ThemeProvider theme={theme}>
          <App id="app-root" />
        </ThemeProvider>
      </Router>
    </React.StrictMode>
  </I18nextProvider>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
