import dayjs from 'dayjs'
import { useContext } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { AppCtx } from '../../constant/contexts'
import { api } from '../../utils/api'
import { Helper, StoreKey } from '../../utils/helper'
import { HOME } from '../home/home-query'
import {
  MsRopcRefreshTokenParams,
  MsRopcRefreshTokenResponse,
  MsRopcSignInParameter,
  MsRopcSignInParams,
  MsRopcSignInResponse,
} from './ms-ropc-types'

export const useMsRopcSignIn = () => {
  const queryClient = useQueryClient()
  const [, setState] = useContext(AppCtx)

  const authUrl: string = Helper.getEncryptValue(StoreKey.authUrl)
  const authClientId: string = Helper.getEncryptValue(StoreKey.authClientId)
  return useMutation(
    async (param: MsRopcSignInParameter) => {
      const appToken = Helper.getEncryptValue(StoreKey.appToken)
      if (!appToken) {
        return Promise.reject('App token is missing')
      }
      const _params: MsRopcSignInParams = {
        username: appToken,
        password: `p1N.#${param.pinCode}`,
        scope: `openid ${authClientId} offline_access`,
        clientId: authClientId,
        grantType: 'password',
        responseType: 'token id_token',
        recaptcha_token: param.reCaptchaKey && `${param.reCaptchaKey}`
      }
      const { data } = await api.msRopc.post<MsRopcSignInResponse>(authUrl, _params)
      setState({ authenPinCode: param.pinCode })
      return data
    },
    {
      onSuccess: (response) => {
        const { idToken, accessToken, tokenType, expiresIn, refreshToken } = response
        Helper.setEncryptValue(StoreKey.idToken, idToken)
        Helper.setEncryptValue(StoreKey.accessToken, accessToken)
        Helper.setEncryptValue(StoreKey.tokenType, tokenType)
        Helper.setEncryptValue(StoreKey.expiresIn, expiresIn)
        Helper.setEncryptValue(StoreKey.refreshToken, refreshToken)

        const expiresOn = dayjs().add(Number(expiresIn), 'second')
        Helper.setEncryptValue(StoreKey.expiresOn, expiresOn.unix())
        queryClient.invalidateQueries([HOME])
      },
    },
  )
}

const setRefreshTokenResponse = (response: MsRopcRefreshTokenResponse) => {
  const {
    accessToken,
    idToken,
    tokenType,
    notBefore,
    expiresIn,
    expiresOn,
    resource,
    idTokenExpiresIn,
    profileInfo,
    scope,
    refreshToken,
    refreshTokenExpiresIn,
  } = response
  Helper.setEncryptValue(StoreKey.accessToken, accessToken)
  Helper.setEncryptValue(StoreKey.idToken, idToken)
  Helper.setEncryptValue(StoreKey.tokenType, tokenType)
  Helper.setEncryptValue(StoreKey.notBefore, notBefore)
  Helper.setEncryptValue(StoreKey.expiresIn, expiresIn)
  Helper.setEncryptValue(StoreKey.expiresOn, expiresOn)
  Helper.setEncryptValue(StoreKey.resource, resource)
  Helper.setEncryptValue(StoreKey.idTokenExpiresIn, idTokenExpiresIn)
  Helper.setEncryptValue(StoreKey.profileInfo, profileInfo)
  Helper.setEncryptValue(StoreKey.scope, scope)
  Helper.setEncryptValue(StoreKey.refreshToken, refreshToken)
  Helper.setEncryptValue(StoreKey.refreshTokenExpiresIn, refreshTokenExpiresIn)
}

export const callRefreshToken = async () => {
  const authUrl: string = Helper.getEncryptValue(StoreKey.authUrl)
  const authClientId: string = Helper.getEncryptValue(StoreKey.authClientId)
  const refreshToken = Helper.getEncryptValue(StoreKey.refreshToken)

  if (!refreshToken) {
    return Promise.reject('RefreshToken is missing')
  }
  const _params: MsRopcRefreshTokenParams = {
    refreshToken,
    resource: authClientId,
    clientId: authClientId,
    grantType: 'refresh_token',
    responseType: 'id_token',
  }
  const { data } = await api.msRopc.post<MsRopcRefreshTokenResponse>(authUrl, _params)
  setRefreshTokenResponse(data)
  return data
}

export const useMsRopcRefreshToken = () => {
  const queryClient = useQueryClient()
  return useMutation(callRefreshToken, {
    onSuccess: (response) => {
      setRefreshTokenResponse(response)
      queryClient.invalidateQueries()
      queryClient.refetchQueries({
        active: true,
      })
    },
  })
}
