import dayjs from 'dayjs'
import { Helper } from './helper'
import { blake2b } from 'hash-wasm'
import { base58 } from './base58'
import config from '../config'

export class ApiHelper {
  static appVersion: string = '1.0'
  static appPlatform: string = 'Browser'

  static getIdToken(): string {
    var idToken = Helper.getIdToken()
    return idToken
  }

  static async generateApimToken() {
    var apimToken = await this.generateApimTokenCore()
    // mockGenerateApimTokenCore();
    return apimToken.encodeBase58
  }

  // static getDeviceInfo() async {
  //   DeviceInfoPlugin deviceInfo = DeviceInfoPlugin();

  //   if (Platform.isIOS) {
  //     appPlatform = 'iOS';
  //     iosInfo = await deviceInfo.iosInfo;
  //     if (iosInfo != null) {
  //       print('Running on ${iosInfo!.utsname.machine}'); // e.g. "iPod7,1"
  //     }
  //   }
  //   if (Platform.isAndroid) {
  //     appPlatform = 'Android';
  //     androidInfo = await deviceInfo.androidInfo;
  //     if (androidInfo != null) {
  //       print('Running on ${androidInfo!.device}'); // e.g. "Moto G (4)"
  //     }
  //   }
  // }

  // static getPackageInfo() async {
  //   packageInfo = await PackageInfo.fromPlatform();

  //   if (packageInfo != null) {
  //     appName = packageInfo!.appName;
  //     packageName = packageInfo!.packageName;
  //     appVersion = packageInfo!.version;
  //     buildNumber = packageInfo!.buildNumber;
  //   }
  //   print({
  //     "appName": appName,
  //     "packageName": packageName,
  //     "appVersion": appVersion,
  //     "buildNumber": buildNumber
  //   });
  // }

  static async generateApimTokenCore() {
    // APIM_SECRET from env
    const apimSecret = config.apimSecret || ''
    const now = dayjs()
    const calMinute = Math.floor(now.minute() / 5) * 5
    const plainDateTime = dayjs(now).minute(calMinute).startOf('minute')
    // Get second epoch
    const seconds = plainDateTime.unix()

    // concat secret
    const plainText = `${apimSecret}${seconds}`

    // encode break2b
    // https://www.toolkitbay.com/tkb/tool/BLAKE2b_256
    const encodeBreak2b = await blake2b(plainText, 256)

    // encode base58
    /// https://www.appdevtools.com/base58-encoder-decoder
    const encodeBreak2bHex = Buffer.from(encodeBreak2b, 'hex')
    const encodeBase58 = base58.encode(Uint8Array.from(encodeBreak2bHex))

    const result = {
      apimSecret,
      now: now.format(),
      plainDateTime: plainDateTime.format(),
      seconds,
      plainText,
      encodeBreak2b,
      encodeBase58: encodeBase58.toString(),
    }

    return result
  }
}
