import { CSSProperties, forwardRef, MouseEventHandler, ReactNode, useMemo } from 'react'
import styled from '@emotion/styled'
import { CambayType } from '../../services/text-typed'
import Truncate from 'react-truncate'
import { TEXT } from '../../constant/colors'

const StyledText = styled.div<{
  family: string
  size: number
  weight: number
  color: string
  style?: CSSProperties
}>`
  font-family: ${({ family }) => `Cambay${family}`};
  font-size: ${({ size }) => size}px;
  font-weight: ${({ weight }) => weight};
  color: ${({ color }) => color};
  word-break: break-word;
`

export interface TextProps {
  onClick?: MouseEventHandler
  className?: string
  children: ReactNode
  size?: number
  type?: CambayType
  color?: string
  style?: CSSProperties
  maxLines?: number
  useEllipsis?: boolean
}
const Cambay = forwardRef((props: TextProps, ref: any) => {
  const {
    children,
    type,
    size,
    color = `${TEXT}`,
    style,
    className,
    maxLines: line = 1,
    useEllipsis = false,
    ...restProps
  } = props
  const family = useMemo(() => {
    switch (type) {
      case 'Huge':
        return '-Bold'
      case 'Heading1':
        return '-Bold'
      case 'Heading2':
        return '-Bold'
      case 'Heading3':
        return '-Bold'
      case 'Heading4':
        return '-Bold'
      case 'Heading5':
        return '-Bold'
      case 'SubHeading1':
        return '-Regular'
      case 'SubHeading2':
        return '-Regular'
      case 'SubHeading3':
        return '-Regular'
      case 'SubHeading4':
        return '-Regular'
      case 'SubHeading5':
        return '-Regular'
      default:
        return '-Regular'
    }
  }, [type])
  const textSize = useMemo(() => {
    switch (type) {
      case 'Huge':
        return 72
      case 'Heading1':
        return 56
      case 'Heading2':
        return 40
      case 'Heading3':
        return 32
      case 'Heading4':
        return 24
      case 'Heading5':
        return 18
      case 'SubHeading1':
        return 56
      case 'SubHeading2':
        return 40
      case 'SubHeading3':
        return 32
      case 'SubHeading4':
        return 24
      case 'SubHeading5':
        return 18
      default:
        return 24
    }
  }, [type])

  const textWeight = useMemo(() => {
    switch (type) {
      case 'Huge':
        return 700
      case 'Heading1':
        return 700
      case 'Heading2':
        return 700
      case 'Heading3':
        return 700
      case 'Heading4':
        return 700
      case 'Heading5':
        return 700
      case 'SubHeading1':
        return 400
      case 'SubHeading2':
        return 400
      case 'SubHeading3':
        return 400
      case 'SubHeading4':
        return 400
      case 'SubHeading5':
        return 400
      default:
        return 400
    }
  }, [type])

  return (
    <StyledText
      ref={ref}
      family={family}
      size={textSize}
      weight={textWeight}
      color={color}
      style={style}
      className={className}
      {...restProps}
    >
      {useEllipsis ? (
        <Truncate lines={line} ellipsis={<span>...</span>}>
          {children}
        </Truncate>
      ) : (
        <>{children}</>
      )}
    </StyledText>
  )
})

export default Cambay
