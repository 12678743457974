import { Button as MuiButton, ButtonProps as MuiButtonProps, Theme } from '@mui/material'
import { PropsWithChildren, useMemo } from 'react'
//import { WHITE_FFFFFF, BLUE_004D99, BLACK_00000D, GRAY_C7C7C7 } from '../../constant/colors'
import MuiCircularProgress from '@mui/material/CircularProgress'
import { makeStyles } from '@mui/styles'
import Roboto from './Roboto'
import { BG_LIGHT, PRIMARY, TEXT_DARK, TEXT_LIGHT, WHITE } from '../../constant/colors'

type StyleAttr = {
  width?: number
  height?: number
  fontSize?: number
  textColor?: string
  disabled?: boolean
}

const useStyle = makeStyles<Theme, StyleAttr>({
  root: {
    fontFamily: "'Roboto', Body Bold",
    fontWeight: 700,
    fontSize: ({ fontSize = 16 }) => `${fontSize / 16}rem`,
    borderRadius: '16px',
    textTransform: 'none',
    minWidth: 200,
    height: ({ height = 48 }) => `${height / 16}rem`,
    width: ({ width }) => (width ? `${width / 16}rem` : undefined),
  },
  fill: {
    backgroundColor: ({ disabled }) => (disabled ? BG_LIGHT : PRIMARY),
    color: ({ disabled }) => (disabled ? TEXT_LIGHT : WHITE),
    '&:hover': {
      backgroundColor: '#004292',
    },
  },
  border: {
    //backgroundColor: 'transparent',
    color: ({ textColor = PRIMARY }) => textColor,
    border: ({ disabled }) => (disabled ? `2px solid ${TEXT_LIGHT}` : `2px solid ${PRIMARY}`),
    '&:hover': {
      backgroundColor: `#ececec`,
    },
  },
  noBackground: {},
})

type ButtonProps = {
  buttonStyle?: 'fill' | 'border' | 'noBackground'
  width?: number
  height?: number
  loading?: boolean
  fontSize?: number
  textColor?: string
} & MuiButtonProps

const Button = (props: PropsWithChildren<ButtonProps>) => {
  const {
    children,
    className = '',
    buttonStyle = 'fill',
    width,
    height,
    loading,
    fontSize = 16,
    textColor,
    disabled,
    ...restProps
  } = props
  const classes = useStyle({ width, height, fontSize, textColor, disabled: props.disabled })

  const getTextColor = useMemo(() => {
    if (disabled) {
      return TEXT_LIGHT
    } else {
      if (buttonStyle === 'fill') {
        return WHITE
      } else {
        return PRIMARY
      }
    }
  }, [buttonStyle, disabled])

  return loading ? (
    <MuiButton>
      <MuiCircularProgress size={`${fontSize / 16}rem`} style={{ color: PRIMARY }} />
    </MuiButton>
  ) : (
    <MuiButton
      className={`${classes.root} ${classes[buttonStyle]} ${className}`}
      disabled={disabled}
      {...restProps}
      // variant="contained"
    >
      <Roboto type="BodyBold" color={textColor ?? getTextColor}>
        {children}
      </Roboto>
    </MuiButton>
  )
}

export default Button
