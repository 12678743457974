import axios, { AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse } from 'axios'
import dayjs from 'dayjs'
import { camelizeKeys, decamelizeKeys } from 'humps'
import { toNumber } from 'lodash'
import { ContentType, customRequestData, deepLoop } from './tools'

const createClient = () => {
  const ax = axios.create()
  ax.interceptors.request.use(async (request: AxiosRequestConfig<any>) => {
    request.url = `${request.url}`
    const headers: AxiosRequestHeaders = {}
    headers['Content-Type'] = ContentType.XFORM
    request.headers = { ...request.headers, ...headers }

    if (request.params) {
      request.params = decamelizeKeys(request.params)
      request.params = deepLoop(request.params, modifyRequestData)
    }
    if (request.data) {
      request.data = decamelizeKeys(request.data)
      request.data = deepLoop(request.data, modifyRequestData)
      customRequestData(request)
    }
    return request
  })
  ax.interceptors.response.use(
    (response: AxiosResponse<any>) => {
      if (response.headers['content-type'].includes('application/json')) {
        response.data = camelizeKeys(response.data) as any
      }
      return response
    },
    (error: any) => Promise.reject(error),
  )
  return ax
}

const modifyRequestData = (data: any) => {
  if (dayjs.isDayjs(data)) {
    return data.format()
  }
  return data
}

export const msRopcClient = createClient()

export const msRopcApiWraper = async (method: Promise<AxiosResponse>) => {
  try {
    const res = await method
    return Promise.resolve(res)
  } catch (e: any) {
    const { response, message } = e
    let { data } = response || {}
    if (data instanceof Blob) {
      const responseFromBlob = await data.text()
      data = JSON.parse(responseFromBlob || '')
    }
    const {
      message: errorMessage,
      errorCode,
      meta = {},
      error,
      error_description,
      failure = 0,
    } = data || {}
    return Promise.reject({
      message: errorCode || errorMessage || message || e,
      meta: meta,
      error: error,
      error_description: error_description,
      failure: failure,
    })
  }
}
