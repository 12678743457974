import dayjs from 'dayjs'
import { debounce, DebounceSettings, some } from 'lodash'
import { useCallback, useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AppModalProps } from '../components/AppModal'
import { AppSnackbarProps } from '../components/AppSnackbar'
import { AppCtx } from '../constant/contexts'
import { useGetServiceableCountries } from '../services/home/home-query'
import { Currency, ServiceableCountry } from '../services/home/home-types'

export const useDebounce = <T extends (...args: any) => any>(
  func: T,
  wait?: number,
  options?: DebounceSettings,
) => {
  return useMemo(() => {
    return debounce(func, wait, options)
  }, [func, options, wait])
}

type UseModalProps = Partial<Omit<AppModalProps, 'visible'>>
type UseModalOptionProps = Partial<
  Pick<UseModalProps, 'style' | 'closeOnClickOutside' | 'okOnRightPosition'>
>
type UseModalConfirmProps = Required<Pick<UseModalProps, 'title' | 'onOk'>> &
  Partial<Pick<UseModalProps, 'message' | 'onOkText' | 'onCancel' | 'onCancelText'>> &
  UseModalOptionProps
type UseModalErrorProps = Required<Pick<UseModalProps, 'title'>> &
  Partial<Pick<UseModalProps, 'message' | 'onOkText' | 'onOk'>> &
  UseModalOptionProps
export const useModal = () => {
  const { t } = useTranslation()
  const [state, setState, { initialValue }] = useContext(AppCtx)
  const openModal = useCallback(
    (props: UseModalProps) => {
      setState({
        appModal: {
          visible: true,
          ...props,
        },
      })
    },
    [setState],
  )

  const confirm = useCallback(
    (props: UseModalConfirmProps) => {
      const {
        title,
        message,
        onOk,
        onOkText = t('button.yes'),
        onCancel,
        onCancelText = t('button.no'),
        ...restProps
      } = props
      openModal({
        title,
        message,
        onOk,
        onOkText,
        onCancel: () => {
          onCancel?.()
        },
        onCancelText,
        ...restProps,
      })
    },
    [openModal, t],
  )

  const error = useCallback(
    (props: UseModalErrorProps) => {
      const { title, message, onOk, onOkText, ...restProps } = props
      openModal({
        title,
        message,
        onOk: () => {
          onOk?.()
        },
        onOkText,
        onCancel: undefined,
        ...restProps,
      })
    },
    [openModal],
  )

  const open = useCallback(
    (props: Pick<UseModalProps, 'children' | 'closeOnClickOutside' | 'style' | 'className'>) => {
      const { children, ...restProps } = props
      openModal({
        children,
        ...restProps,
      })
    },
    [openModal],
  )

  const closeModal = useCallback(() => {
    setState({
      appModal: initialValue.appModal,
    })
  }, [initialValue.appModal, setState])

  return { confirm, error, open, closeModal, visible: state.appModal.visible }
}
const serviceableCountryTh: ServiceableCountry = {
  country: 'TH',
  countryDesc: 'Thailand',
  availablePaymentChannel: [],
  currencies: [
    {
      currency: 'THB',
      currencyDesc: 'Thai Baht',
      fxRate: 1,
      timestamp: dayjs().format(),
      transactionLimit: 10000,
      transactionLimitThb: 10000,
      fee: 0,
    },
  ],
}

type GetCountryOption = {
  includeTh: boolean
}

export const useServiceableCountriesHelper = () => {
  const { data: serviceableCountries } = useGetServiceableCountries()

  const countryOptions = useMemo(() => {
    return (
      serviceableCountries?.map((countryItem) => {
        return {
          value: countryItem.country,
          label: `${countryItem.countryDesc}`,
        }
      }) || []
    )
  }, [serviceableCountries])

  const getCurrencies = useCallback(
    (country: string) => {
      const currencies =
        serviceableCountries?.find((serviceableCountry) => serviceableCountry.country === country)
          ?.currencies || []
      return currencies
    },
    [serviceableCountries],
  )

  const getPaymentChannelFormCountry = useCallback(
    (country: string) => {
      const paymentChannelOption =
        serviceableCountries?.find(
          (serviceableCountries) => serviceableCountries.country === country,
        )?.availablePaymentChannel || []
      return paymentChannelOption
    },
    [serviceableCountries],
  )

  const getCurrenciesOptions = useCallback(
    (country: string) => {
      const currenciesOptions = getCurrencies(country).map((currency) => {
        return {
          value: currency.currency,
          label: `${currency.currency} - ${currency.currencyDesc}`,
        }
      })
      return currenciesOptions
    },
    [getCurrencies],
  )

  const getCurrency = useCallback(
    (currency: string) => {
      const currencies = serviceableCountries
        ? serviceableCountries.reduce((acc: Currency[], cur) => {
            acc = acc.concat(cur.currencies)
            return acc
          }, [])
        : []
      return currencies.find((v) => v.currency === currency)
    },
    [serviceableCountries],
  )

  const getCountryFromCurrency = useCallback(
    (currency: string, option: GetCountryOption = { includeTh: false }) => {
      if (option?.includeTh && currency === 'THB') {
        return serviceableCountryTh
      }
      return serviceableCountries?.find((serviceableCountry) =>
        some(serviceableCountry.currencies, (v) => v.currency === currency),
      )
    },
    [serviceableCountries],
  )

  const getCountryFromCountryName = useCallback(
    (country: string, option: GetCountryOption = { includeTh: false }) => {
      if (option?.includeTh && country === 'TH') {
        return serviceableCountryTh
      }
      return serviceableCountries?.find(
        (serviceableCountry) => serviceableCountry.country === country,
      )
    },
    [serviceableCountries],
  )

  return {
    countryOptions,
    getCurrencies,
    getCurrenciesOptions,
    getCurrency,
    getCountryFromCurrency,
    getCountryFromCountryName,
    getPaymentChannelFormCountry,
  }
}

export const useVisible = (defaultVisible: boolean = false) => {
  const [visible, setVisible] = useState(defaultVisible)
  const open = useCallback(() => {
    setVisible(true)
  }, [])
  const close = useCallback(() => {
    setVisible(false)
  }, [])

  return {
    visible,
    open,
    close,
  }
}

type UseSnackbarProps = Partial<Omit<AppSnackbarProps, 'visible'>>
export const useSnackbar = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_state, setState] = useContext(AppCtx)
  const openSnackbar = useCallback(
    (props: UseSnackbarProps) => {
      setState({
        appSnackbar: {
          visible: true,
          ...props,
        },
      })
    },
    [setState],
  )

  const snackbar = useCallback(
    (props: Pick<UseSnackbarProps, 'message' | 'type' | 'description'>) => {
      const { message, type = 'info', description = '' } = props
      openSnackbar({
        message,
        type,
        description,
      })
    },
    [openSnackbar],
  )

  return { snackbar }
}
