export const WHITE = '#FFFFFF'
export const PRIMARY = '#004BA6'
export const SECONDARY = '#33BDA1'
export const PRIMARY_LIGHT = '#99B7DB'
export const SECONDARY_LIGHT = '#ADE5D9'
export const INFO = '#2172C3'
export const SUCCESS = '#2ECC71'
export const ERROR = '#E0255F'
export const WARNING = '#FFBA00'
export const TEXT = '#212121'
export const TEXT_DARK = '#888888'
export const TEXT_LIGHT = '#BBBBBB'
export const BG_DARK = '#E1E1E1'
export const BG_LIGHT = '#F5F5F5'
export const SOFT_PRIMARY = '#336FB8'
