import { CSSProperties, ReactNode, useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { PRIMARY, TEXT_LIGHT, WHITE } from '../constant/colors'
import { AppCtx } from '../constant/contexts'
import Button from './common/Button'
import Modal from '@mui/material/Modal'
import { css } from '@emotion/css'
import Cambay from './common/Cambay'
import Roboto from './common/Roboto'

const classes = {
  appModal: css`
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  backdrop: {
    backgroundColor: '#000000bf',
  },
  layout: css`
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    background: ${WHITE};

    min-width: 512px;
    padding: 24px;
    border-radius: 16px;
    box-sizing: border-box;
  `,
  header: css`
    margin-bottom: 16px;
    text-align: left;
  `,
  body: css`
    text-align: center;
  `,
  footer: css`
    display: flex;
    justify-content: center;
    grid-column-gap: 8px;
    margin-top: 40px;
    > button {
      flex: 1;
    }
  `,
}

export type AppModalProps = {
  visible: boolean
  title?: string
  message?: ReactNode
  children?: ReactNode
  onOk?: () => void
  onOkText?: string
  onCancel?: () => void
  onCancelText?: string
  closeOnClickOutside?: boolean
  style?: CSSProperties
  className?: string
  okOnRightPosition?: boolean
}
const AppModal = () => {
  const [state, setState, { initialValue }] = useContext(AppCtx)
  const {
    visible,
    title,
    message,
    children,
    onOk,
    onOkText,
    onCancel,
    onCancelText,
    closeOnClickOutside = true,
    style,
    className,
    okOnRightPosition = false,
  } = state.appModal

  const { t } = useTranslation()
  const onClose = useCallback(() => {
    setState({
      appModal: initialValue.appModal,
    })
  }, [initialValue.appModal, setState])

  const onOkClick = useCallback(async () => {
    await onOk?.()
    onClose()
  }, [onOk, onClose])

  const onCancelClick = useCallback(async () => {
    await onCancel?.()
    onClose()
  }, [onCancel, onClose])

  return (
    <Modal
      open={visible}
      onClose={() => {
        closeOnClickOutside && onCancelClick()
      }}
      className={`${classes.appModal} ${className}`}
      aria-labelledby="app-modal-title"
      aria-describedby="app-modal-description"
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      disableRestoreFocus
      BackdropProps={{ style: classes.backdrop }}
    >
      <div
        className={`content ${classes.layout}`}
        style={{
          ...style,
        }}
      >
        {!children && (
          <>
            {title && (
              <div className={classes.header}>
                <Cambay type="Heading4" color={PRIMARY}>
                  {t(title)}
                </Cambay>
              </div>
            )}
            {message &&
              (typeof message === 'string' ? (
                <div className={classes.body}>
                  <Roboto color={TEXT_LIGHT} type={'Body'}>
                    {t(message)}
                  </Roboto>
                </div>
              ) : (
                message
              ))}
            {okOnRightPosition ? (
              <div className={classes.footer}>
                {onCancel && (
                  <Button buttonStyle="border" onClick={onCancelClick}>
                    {t(onCancelText || 'button.no')}
                  </Button>
                )}
                {onOk && (
                  <Button buttonStyle="fill" onClick={onOkClick}>
                    {t(onOkText || 'button.yes')}
                  </Button>
                )}
              </div>
            ) : (
              <div className={classes.footer}>
                {onOk && (
                  <Button buttonStyle="border" onClick={onOkClick}>
                    {t(onOkText || 'button.yes')}
                  </Button>
                )}
                {onCancel && (
                  <Button buttonStyle="fill" onClick={onCancelClick}>
                    {t(onCancelText || 'button.no')}
                  </Button>
                )}
              </div>
            )}
          </>
        )}
        {children && children}
      </div>
    </Modal>
  )
}

export default AppModal
