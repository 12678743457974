import { last } from 'lodash'
import { useContext } from 'react'
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query'
import { AppCtx } from '../../constant/contexts'
import { api } from '../../utils/api'
import { Helper, KycStatus, StoreKey, enumFromStringValue } from '../../utils/helper'
import {
  ChangeConsentPDPAParams,
  ChangeConsentResponse,
  ChangePinParams,
  ChangePinResponse,
  GetHomeResponse,
  Notification,
  NotificationItemResponse,
  NotificationResponse,
  SignOutResponse,
  ToggleNotiResponse,
} from './home-types'

export const HOME = 'home'
export const HOME_SETTINGS = `${HOME}/settings`
export const SIGN_OUT = `${HOME_SETTINGS}/signout`
export const CHANGE_CONSENT = `${HOME}/settings/change_consent`
export const TOGGLE_NOTIFICATION = `${HOME_SETTINGS}/toggle_notification`
export const CURRENT_USER = ``
export const NOTIFICATION = `${HOME}/noti`
export const NOTIFICATIONREAD = `${NOTIFICATION}/read`
export const CHANGE_PIN = `${HOME_SETTINGS}/change_pin`

export const useGetHome = (options?: UseQueryOptions<GetHomeResponse>) => {
  const [, setState] = useContext(AppCtx)
  const queryClient = useQueryClient()
  return useQuery<GetHomeResponse>(
    [HOME],
    async () => {
      const { data } = await api.smartSwift.get<GetHomeResponse>(`${HOME}`)
      if (data.userInfo) {
        Helper.setEncryptValue(StoreKey.isLogin, true)
        const avatarUrl = data.userInfo.avatarUrl
        const avatarId = avatarUrl && last(avatarUrl.split('/'))
        const kycStatus = enumFromStringValue(KycStatus, data.userInfo.kycStatus)
        setState({
          kycStatus: kycStatus,
          avatarId: avatarId,
          userDisplay: data.userInfo.firstName,
          totalUnreadNoti: data.totalUnreadNoti,
        })
      } else {
        Helper.setEncryptValue(StoreKey.isLogin, false)
      }
      return data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([NOTIFICATION])
      },
      ...options,
      useErrorBoundary: false,
    },
  )
}

export const useGetCurrentUser = () => {
  return useQuery(
    [HOME, CURRENT_USER],
    async () => {
      const { data } = await api.smartSwift.get<GetHomeResponse>(`${HOME}`)
      return data.userInfo
    },
    {
      cacheTime: 30 * 60 * 1000,
      staleTime: 30 * 60 * 1000,
    },
  )
}

export const useGetSignOut = () => {
  const queryClient = useQueryClient()
  const [, setState] = useContext(AppCtx)
  return useMutation(
    async () => {
      const { data } = await api.smartSwift.get<SignOutResponse>(`${SIGN_OUT}`)
      return data
    },
    {
      onSuccess: () => {
        Helper.clearStore()
        setState({ kycStatus: undefined })
        queryClient.invalidateQueries([HOME])
      },
    },
  )
}

export const useGetServiceableCountries = (
  options?: UseQueryOptions<GetHomeResponse['serviceableCountries']>,
) => {
  return useQuery<GetHomeResponse['serviceableCountries']>(
    [HOME, 'serviceable-countries'],
    async () => {
      const { data } = await api.smartSwift.get<GetHomeResponse>(`${HOME}`)
      return data.serviceableCountries
    },
    {
      cacheTime: 30 * 1000,
      staleTime: 30 * 1000,
      useErrorBoundary: false,
      ...options,
    },
  )
}

export const useChangeConsentPDPA = () => {
  const mutate = useMutation(async (params: ChangeConsentPDPAParams) => {
    return await api.smartSwift.post<ChangeConsentResponse>(`${CHANGE_CONSENT}`, params)
  }, {})
  return mutate
}

export const useToggleNotification = () => {
  const queryClient = useQueryClient()
  const mutate = useMutation(
    async () => {
      return await api.smartSwift.post<ToggleNotiResponse>(`${TOGGLE_NOTIFICATION}`)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([HOME])
      },
    },
  )
  return mutate
}

export const useGetNotifications = () => {
  return useInfiniteQuery(
    [NOTIFICATION],
    async ({ pageParam = 1 }) => {
      const { data } = await api.smartSwift.get<NotificationResponse>(
        `${NOTIFICATION}/${pageParam}`,
      )
      return data
    },
    {
      cacheTime: 0,
      staleTime: 0,
      getNextPageParam: (response) => {
        if (response.data?.meta) {
          const nextPage = response.data?.meta?.currentPage + 1
          if (nextPage <= response.data?.meta?.totalPages) {
            return nextPage
          } else {
            return null
          }
        } else {
          return null
        }
      },
    },
  )
}

export const useGetNotificationsItem = (notiSelected: Notification) => {
  const queryClient = useQueryClient()
  const [state] = useContext(AppCtx)
  const [, setState] = useContext(AppCtx)
  return useQuery<NotificationItemResponse>(
    [NOTIFICATIONREAD, notiSelected],
    async () => {
      const { data } = await api.smartSwift.get<NotificationItemResponse>(
        `${NOTIFICATIONREAD}/${notiSelected.notiId}`,
      )

      return data
    },
    {
      onSuccess: () => {
        const totalUnreadNoti = state.totalUnreadNoti

        if (notiSelected.isRead === false && totalUnreadNoti !== undefined) {
          const unreadNoti = totalUnreadNoti - 1

          setState({
            totalUnreadNoti: unreadNoti,
          })
        }
        queryClient.invalidateQueries([NOTIFICATION])
      },
    },
  )
}

export const useChangePin = () => {
  const queryClient = useQueryClient()
  const mutate = useMutation(
    async (params: ChangePinParams) => {
      return await api.smartSwift.post<ChangePinResponse>(`${CHANGE_PIN}`, params)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([HOME])
      },
    },
  )
  return mutate
}
