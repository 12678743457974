const config = {
  mode: (window as any)._env_.mode,
  apiHost: (window as any)._env_.apiHost,
  apimSecret: (window as any)._env_.apimSecret,
  // appClientId: (window as any)._env_.appClientId,
  // apiMsRopcHost: (window as any)._env_.apiMsRopcHost,
  // azureTenantName: (window as any)._env_.azureTenantName,
  // azureTenantId: (window as any)._env_.azureTenantId,
  // azureTokenUrl: (window as any)._env_.azureTokenUrl,
  reCaptchaSiteKey: (window as any)._env_.reCaptchaSiteKey
}
export default config
