import { Alert, Snackbar } from '@mui/material'
import { useCallback, useContext } from 'react'
import { ERROR, INFO, SECONDARY, WHITE } from '../constant/colors'
import { AppCtx } from '../constant/contexts'
import Roboto from './common/Roboto'

export type AppSnackbarProps = {
  visible: boolean
  message?: string
  description?: string
  type?: 'error' | 'info' | 'success' | 'warning'
}

export const AppSnackbar = () => {
  const [state, setState, { initialValue }] = useContext(AppCtx)
  const color = useCallback(() => {
    if (state.appSnackbar.type === 'success') {
      return SECONDARY
    } else if (state.appSnackbar.type === 'error') {
      return ERROR
    } else {
      return INFO
    }
  }, [state.appSnackbar.type])

  const handleClose = (event: Event | React.SyntheticEvent) => {
    // if (reason === 'clickaway') {
    //   return
    // }

    setState({
      appSnackbar: initialValue.appSnackbar,
    })
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      open={state.appSnackbar.visible}
      autoHideDuration={3000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} variant="filled" sx={{ background: color() }}>
        <Roboto type={'Body'} color={WHITE}>
          {state.appSnackbar.message}
        </Roboto>
      </Alert>
    </Snackbar>
  )
}
